import {mapActions, mapGetters} from 'vuex';
import _laxios from "../../../../store/_laxios";
import AddCard from "./../AddCard/index.vue";

export default {
    name: 'ItemCard',
    data() {
        return {
            isShow: false
        }
    },
    components: {
        'add-card': AddCard,
    },
    computed: {
        ...mapGetters({
            cards_: 'cabinet/cards',
        }),
        cards() {
            return this.cards_ || {};
        }
    },
    methods: {
        ...mapActions({
            getCards_: 'cabinet/getCards',
            deleteCard_: 'cabinet/deleteCard',
            setMainCard_: 'cabinet/setMainCard',
        }),
        openPopupDeleteCard(cardId) {
            this.isShow = true;
            this.cardId = cardId
        },
        deleteCard() {
            const cardId = this.cardId;
            this.isShow = false;
            this.deleteCard_({cardId}).then(() => this.getCards_())
        },
        closePopup() {
            this.isShow = false;
        },
        setMainCard(cardId) {
            this.setMainCard_({cardId}).then(() => this.getCards_())
        }
    },
    i18n: {
        messages: {
            uk: {
                'Видалити': 'Видалити',
                'Ви впевнені, що бажаєте видалити карту': 'Ви впевнені, що бажаєте видалити карту',
                'Ні, відмінити': 'Ні, відмінити',
                'Так, впевнений': 'Так, впевнений',
                'Основна': 'Основна',
            },
            ru: {
                'Видалити': 'Удалить',
                'Ви впевнені, що бажаєте видалити карту': 'Вы уверены, что хотите удалить карту',
                'Ні, відмінити': 'Нет, отменить',
                'Так, впевнений': 'Да, уверен',
                'Основна': 'Основная',
            }
        }
    }
}
