import {mapActions, mapGetters} from "vuex";

export default {
    name: 'PopupAdd',
    data() {
        return {
            isShow: false,
        }
    },
    computed: {
        ...mapGetters({
            userData: 'cabinet/userInfo/userData',
        }),
        userId() {
            return this.userData.id;
        },
    },
    methods: {
        ...mapActions({
            getCards_: 'cabinet/getCards'
        }),
        openPopup() {
            this.isShow = true;
        },
        closePopup() {
            this.isShow = false;
            this.getCards_();
        },
        listenerMessage(event){
            if (event.data.cmd == 'liqpay.close' ) {
                this.closePopup()
            }
        },
    },
    mounted(){
        window.addEventListener('message', this.listenerMessage, false);
    },
    i18n: {
        messages: {
            uk: {
                'Додати іншу карту': 'Додати іншу карту',
            },
            ru: {
                'Додати іншу карту': 'Добавить другую карту',
            }
        }
    }
}
