import { mapActions, mapGetters } from 'vuex'
import ItemCard from "../SpecifyCards/ItemCard/index.vue";
import translate from "./i18n";
const PERIOD_PROLONGATION = 1 // 1 Month

export default {
    name: 'RegularPayments',
    data() {
        return {
            showConfirmPopup: false
        }
    },
    components: {
        'item-card': ItemCard,
    },
    computed: {
        ...mapGetters( 'cabinet' ,{
            cards: 'cards',
            autoDealerProlongation: 'billing/autoDealerProlongation',
        }),
        formatDate() {
            if(!this.autoDealerProlongation?.prolongationDate) return null

            const date = new Date(this.autoDealerProlongation.prolongationDate);
            return this.m(date).format('DD.MM.YYYY')
        },
        mainCard() {
            return Object.values(this.cards).find(card => card.main)
        },
        minDate(){
            return this.getDate(Date.now());
        },
    },
    methods: {
        ...mapActions('cabinet/billing', {
            updateAutoDealerProlongationData: 'updateAutoDealerProlongationData',
            postAutoDealerProlongation: 'postAutoDealerProlongation'
        }),
        confirmPayment(){
            this.postAutoDealerProlongation();
            this.showConfirmPopup = false;
        },
        updateProlongationData({ name, value }) {
            this.updateAutoDealerProlongationData({[name]: value})
        },

        getDate( date, {days, months, years} = {days:0,months:0,years:0}) {
            const startDate = new Date(date)
            return this.m(startDate).add(years,'years').add(months,'months').add(days,'days').format('YYYY-MM-DD');
        },
    },
    init({ store, context }) {
        let Cookies = {};
        if (context) {
            Cookies = context.request.headers.cookie;
        }
        return store.dispatch('cabinet/billing/getAutoDealerProlongationData', Cookies)
    },
    mounted () {
        this.getDate(Date.now(), { months: PERIOD_PROLONGATION })
    },
    i18n: {
       ...translate
    }
}
