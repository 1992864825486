export default {
    messages: {
        uk: {
            'Ваші регулярні платежі': 'Ваші регулярні платежі',
            'Налаштуйте оплати, які здійснюєте на регулярній основі.': 'Налаштуйте оплати, які здійснюєте на регулярній основі.',
            'Вкажіть дані для поповнення пакету Автодилер': 'Вкажіть дані для поповнення пакету “Автодилер”',
            'Автодилер Преміум': 'Автодилер Преміум',
            'Сума поповнення, грн': 'Сума поповнення, грн',
            'Дата та час повторного поповнення': 'Дата та час повторного поповнення',
            'Автоматично продовжувати на місяць': 'Автоматично продовжувати на місяць (до {time})', // exp 9.08.2022
            'Оберіть карту': 'Оберіть карту',
            'Збережені дані': 'Збережені дані',
            'У вказані дату й час кошти будуть списані з вашої картки': 'У вказані дату й час кошти будуть списані з вашої картки',
            'Підтвердити платіж': 'Підтвердити платіж',
            'Підтвердіть оплату пакету “Автодилер”': 'Підтвердіть оплату пакету “Автодилер”',
            'на 30 днів з вказаної дати проведення платежу': 'на 30 днів з вказаної дати проведення платежу',
            'Відміна': 'Відміна',
            'Зверніть увагу!': 'Зверніть увагу!',
            'Кошти на оплату пакета "Автодилер" списуються з основної картки': 'Кошти на оплату пакета "Автодилер" списуються з основної картки',
            'Кошти за оплату пакету "Автодилера" списуються з особистого рахунку': 'Кошти за оплату пакету "Автодилера" списуються з особистого рахунку',
            'Підтвердіть': 'Підтвердіть',
            'автоматичну': 'автоматичну',
            'оплату пакету': 'оплату пакету',
            'який буде': 'який буде',
            'нараховуватись кожен місяць': 'нараховуватись кожен місяць',
            'нарахований': 'нарахований',
            'після проведення оплати': 'після проведення оплати',
        },
        ru: {
            'Ваші регулярні платежі': 'Ваши регулярные платежи',
            'Налаштуйте оплати, які здійснюєте на регулярній основі.': 'Настройте оплаты, производимые на регулярной основе.',
            'Вкажіть дані для поповнення пакету Автодилер': 'Укажите данные для пополнения пакета “Автодилер”',
            'Автодилер Преміум': 'Автодилер Премиум',
            'Сума поповнення, грн': 'Сумма пополнения, грн',
            'Дата та час повторного поповнення': 'Дата и время повторного пополнения',
            'Автоматично продовжувати на місяць': 'Автоматически продлевать на месяц (до {time})', // exp 9.08.2022
            'Оберіть карту': 'Выберите карту',
            'Збережені дані': 'Сохраненные данные',
            'У вказані дату й час кошти будуть списані з вашої картки': 'В указанные дату и время средства будут списаны с вашей карты',
            'Підтвердити платіж': 'Подтвердить платеж',
            'Підтвердіть оплату пакету “Автодилер”': 'Подтвердите оплату пакета “Автодилер”',
            'на 30 днів з вказаної дати проведення платежу': 'на 30 дней с указанной даты проведения платежа',
            'Відміна': 'Отмена',
            'Зверніть увагу!': 'Обратите внимание!',
            'Кошти на оплату пакета "Автодилер" списуються з основної картки': 'Средства на оплату пакета "Автодилер" списываются с основной карты',
            'Кошти за оплату пакету "Автодилера" списуються з особистого рахунку': 'Средства за оплату пакета "Автодилера" списываются с личного счета',
            'Підтвердіть': 'Подтвердите',
            'автоматичну': 'автоматическую',
            'оплату пакету': 'оплату пакета',
            'який буде': 'которий будет',
            'нараховуватись кожен місяць': 'начисляться каждый месяц',
            'нарахований': 'начислен',
            'після проведення оплати': 'после проведения оплаты',
        }
    }
}
